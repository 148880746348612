import styled from "styled-components";
import { theme } from "../../theme";
import { PropsWithChildren } from "react";
import { X } from "../atoms/icons/X";
import { Title } from "../atoms/Title";

export const Modal = ({
  title,
  children,
  onClose,
}: PropsWithChildren<{ title?: string; onClose: () => void }>) => {
  const handleClose = (e: React.MouseEvent) => {
    // Close the modal if clicked outside the content area
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <OuterContainer onClick={handleClose}>
      <InnerContainer>
        <Header>
          {title ? <Title>{title}</Title> : null}
          <CloseButton onClick={onClose}>
            <X width={24} height={24} fillColor="white" />
          </CloseButton>
        </Header>
        <ContentContainer>{children}</ContentContainer>
      </InnerContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.modalBackgroundColor};
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  position: relative;
  z-index: 1;
  padding: 20px;
  gap: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 15px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 5px;
  margin-right: -5px;
  aspect-ratio: 1;
  cursor: pointer;
`;

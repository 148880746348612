import styled from "styled-components";
import { WebhookRequestFragment } from "../../../data/generated";
import { formatTimestamp } from "../../../utils/date";
import { theme } from "../../../theme";
import { CodeSample } from "../../../design-system/atoms/CodeSample";

export const WebhookRequestDetailsRow = ({
  webhookRequest,
}: {
  webhookRequest: WebhookRequestFragment;
}) => {
  const { finishedAt, requestedAt, route, statusCode, body } = webhookRequest;

  return (
    <Row>
      <Cell style={{ width: 350 }}>
        <CodeSample>{route}</CodeSample>
      </Cell>

      <Cell style={{ width: 70 }}>
        <CellContent $hasMinHeight $isCentered>
          {statusCode}
        </CellContent>
      </Cell>
      <Cell style={{ width: 170 }}>
        <CellContent $hasMinHeight>{formatTimestamp(requestedAt)}</CellContent>
      </Cell>
      <Cell style={{ width: 170 }}>
        <CellContent $hasMinHeight>{formatTimestamp(finishedAt)}</CellContent>
      </Cell>
      <Cell style={{ flex: 1, minWidth: 320 }}>
        <CodeSample>{JSON.stringify(body).replaceAll(",", ", ")}</CodeSample>
      </Cell>
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px;
`;

const Cell = styled.div`
  color: ${theme.textColor.default};
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
`;

const CellContent = styled.div<{
  $hasMinHeight?: boolean;
  $isCentered?: boolean;
}>`
  display: flex;
  align-items: center;

  /* justify-content: center; */
  justify-content: ${({ $isCentered }) =>
    $isCentered ? "center" : "flex-start"};
  min-height: ${({ $hasMinHeight }) => ($hasMinHeight ? "39px" : 0)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

import styled from "styled-components";
import { useGetOngoingCommunicationsQuery } from "../../../data/generated";
import { theme } from "../../../theme";
import { OngoingCommunicationDetailsRow } from "./OngoingCommunicationDetailsRow";
import { Loader } from "../../../design-system/atoms/Loader";
import { Title } from "../../../design-system/atoms/Title";

export const OngoingCommunicationsList = ({
  filters,
}: {
  filters: {
    moduleSerialNumber: string;
  };
}) => {
  const { data, loading, error, fetchMore } = useGetOngoingCommunicationsQuery({
    variables: { limit: 50, offset: 0, filters },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return null;
  }

  if (loading || !data) {
    return <Loader />;
  }

  const {
    ongoingCommunications: { ongoingCommunications, hasMore },
  } = data;

  function handleScroll({ currentTarget }: React.UIEvent<HTMLDivElement>) {
    if (
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight
    ) {
      if (hasMore) {
        fetchMore({
          variables: {
            offset: ongoingCommunications.length,
            limit: 50,
            filters,
          },
        });
      }
    }
  }

  return (
    <Container>
      <Header>
        <Title>Ongoing communications</Title>
      </Header>
      <TableContainer onScroll={handleScroll}>
        <Table>
          <thead>
            <Tr>
              <Th>Module serial number</Th>
              <Th>Ordered at</Th>
              <Th>Processed at</Th>
              <Th>Command</Th>
              <Th>Data</Th>
            </Tr>
          </thead>
          <tbody>
            {ongoingCommunications.map((ongoingCommunication) => (
              <OngoingCommunicationDetailsRow
                key={ongoingCommunication._id}
                ongoingCommunication={ongoingCommunication}
              />
            ))}
          </tbody>
        </Table>
        {ongoingCommunications.length === 0 ? (
          <NoResultContainer>
            <p>No logs found</p>
          </NoResultContainer>
        ) : null}
      </TableContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid ${theme.borderColor.highlight};
  border-radius: 10px;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 15px;
`;

const TableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const Table = styled.table`
  width: 100%;
`;

const Tr = styled.tr`
  background-color: ${theme.backgroundColor};
`;

const Th = styled.td<{ $isCentered?: boolean }>`
  position: sticky;
  top: 0px;
  background-color: ${theme.backgroundColor};
  font-weight: bold;
  text-align: ${({ $isCentered }) => ($isCentered ? "center" : "left")};
  padding: 5px;
  z-index: 1;
`;

const NoResultContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${theme.textColor.secondary};
`;

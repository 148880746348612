import styled from "styled-components";
import { theme } from "../../theme";

export const IconButton = ({
  title,
  onClick,
  icon,
  isDisabled,
}: {
  title: string;
  onClick: () => void;
  icon: React.ReactElement;
  isDisabled?: boolean;
}) => {
  return (
    <Button
      title={title}
      onClick={onClick}
      disabled={isDisabled}
      $isDisabled={isDisabled}
    >
      {icon}
    </Button>
  );
};

const Button = styled.button<{ $isDisabled?: boolean }>`
  border: none;
  outline: none;
  border-radius: 5px;
  aspect-ratio: 1;
  cursor: ${({ $isDisabled }) => ($isDisabled ? "default" : "pointer")};

  background-color: ${({ $isDisabled }) =>
    $isDisabled
      ? theme.button.primary.disabled.backgroundColor
      : theme.button.primary.backgroundColor};
  color: ${theme.button.primary.color};

  &:hover {
    opacity: ${({ $isDisabled }) => ($isDisabled ? 1 : 0.5)};
  }
`;

import styled from "styled-components";
import { useGetPowerBanksInfoQuery } from "../../../data/generated";
import { Loader } from "../../../design-system/atoms/Loader";
import { theme } from "../../../theme";

export const PowerBanksInfo = () => {
  const { data } = useGetPowerBanksInfoQuery();

  if (!data) {
    return <Loader />;
  }

  const {
    powerBanksInfo: { totalCount, loanedCount },
  } = data;

  return (
    <Label>
      {totalCount} power banks ({loanedCount} loaned)
    </Label>
  );
};

const Label = styled.span`
  color: ${theme.textColor.secondary};
`;

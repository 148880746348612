import { useState } from "react";
import { Input } from "../../../../design-system/atoms/Input";
import { Modal } from "../../../../design-system/organisms/Modal";
import { Button } from "../../../../design-system/atoms/Button";
import { toast } from "react-toastify";
import {
  ModuleFragment,
  useUpdateModuleMiddlewareUrlMutation,
} from "../../../../data/generated";
import styled from "styled-components";

export const UpdateMiddlewareUrlModal = ({
  module,
  onClose,
}: {
  module: ModuleFragment;
  onClose: () => void;
}) => {
  const [newMiddlewareUrl, setNewMiddlewareUrl] = useState("");

  const [updateModuleMiddlewareUrlMutation] =
    useUpdateModuleMiddlewareUrlMutation();

  async function updateModuleMiddlewareUrl() {
    const { data } = await updateModuleMiddlewareUrlMutation({
      variables: { serialNumber: module.serialNumber, url: newMiddlewareUrl },
    });

    if (!data) {
      toast.error("An unexpected error occurred");
      return;
    }

    const {
      updateModuleMiddlewareUrl: { success, error },
    } = data;

    if (error) {
      toast.error(error);
      return;
    }

    if (!success) {
      toast.error("An unexpected error occurred");
      return;
    }

    toast.success("Middleware url updated successfully");
    onClose();
  }

  return (
    <Modal title="Update middleware URL" onClose={onClose}>
      <ContentContainer>
        You're about to update the middleware URL of the module with Serial
        Number {module.serialNumber}.
        <Input
          label="Middleware URL"
          value={newMiddlewareUrl}
          onChange={(value) => setNewMiddlewareUrl(value)}
        />
        <Button onClick={updateModuleMiddlewareUrl}>Submit</Button>
      </ContentContainer>
    </Modal>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

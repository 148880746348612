import styled from "styled-components";
import { useGetModulesInfoQuery } from "../../../data/generated";
import { Loader } from "../../../design-system/atoms/Loader";
import { theme } from "../../../theme";

export const ModulesInfo = () => {
  const { data } = useGetModulesInfoQuery();

  if (!data) {
    return <Loader />;
  }

  const {
    modulesInfo: { totalCount, offlineCount, potentiallyOfflineCount },
  } = data;

  return (
    <Label>
      {totalCount} module{totalCount > 1 ? "s" : ""}: {offlineCount} offline,{" "}
      {potentiallyOfflineCount} potentially offline (online + seen more than 3
      minutes ago)
    </Label>
  );
};

const Label = styled.span`
  color: ${theme.textColor.secondary};
`;

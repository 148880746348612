import styled from "styled-components";
import { ModulesList } from "./components/ModulesList";
import { Title } from "../../design-system/atoms/Title";
import { Input } from "../../design-system/atoms/Input";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { updateSearchParam } from "../../utils/searchParams";
import { ModulesInfo } from "./components/ModulesInfo";
import { Select } from "../../design-system/atoms/Select";

const connectionStatusOptions = [
  { value: true, label: "Online" },
  { value: false, label: "Offline" },
];

export const Modules = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState({
    moduleSerialNumber: searchParams.get("moduleSerialNumber") || "",
    powerBankSerialNumber: searchParams.get("powerBankSerialNumber") || "",
    isOnline: searchParams.get("isOnline")
      ? Boolean(searchParams.get("isOnline"))
      : null,
  });

  useEffect(() => {
    const filterKeys = [
      { key: "moduleSerialNumber", value: filters.moduleSerialNumber },
      { key: "powerBankSerialNumber", value: filters.powerBankSerialNumber },
      { key: "isOnline", value: filters.isOnline },
    ];

    filterKeys.forEach(({ key, value }) => {
      updateSearchParam(searchParams, key, value);
    });

    setSearchParams(searchParams);
  }, [filters, searchParams, setSearchParams]);

  return (
    <Container>
      <Header>
        <Title>Modules</Title>
        <ModulesInfo />
      </Header>
      <FiltersContainer>
        <Input
          label="Module Serial Number"
          sublabel="Ignored if Power Bank Serial Number is filled"
          placeholder="12345678"
          value={filters.moduleSerialNumber}
          onChange={(value: string) =>
            setFilters({ ...filters, moduleSerialNumber: value })
          }
        />
        <Input
          label="Power Bank Serial Number"
          placeholder="12345678"
          value={filters.powerBankSerialNumber}
          onChange={(value: string) =>
            setFilters({ ...filters, powerBankSerialNumber: value })
          }
        />
        <Select
          label="Connection status"
          value={connectionStatusOptions.find(
            ({ value }) => value === filters.isOnline
          )}
          options={connectionStatusOptions}
          onChange={(option) =>
            setFilters({ ...filters, isOnline: option?.value ?? null })
          }
          isClearable
        />
      </FiltersContainer>
      <ModulesList filters={filters} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  overflow: hidden;
  padding: 15px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

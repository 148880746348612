import styled from "styled-components";
import { LineChart } from "@mui/x-charts";
import { useGetApiRequestsForChartQuery } from "../../../data/generated";
import { Loader } from "../../../design-system/atoms/Loader";
import { theme } from "../../../theme";
import { formatDateForChart } from "../../../utils/date";

export const ApiRequestsChart = ({
  timePeriodInHours,
}: {
  timePeriodInHours: number;
}) => {
  const { data, loading } = useGetApiRequestsForChartQuery({
    variables: { timePeriodInHours },
  });

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  const { apiRequestsForChart: apiRequests } = data;

  const currentTimestamp = new Date().getTime();

  const dataset = apiRequests.reduce(
    (acc, apiRequest) => {
      const requestTimestamp = new Date(apiRequest.createdAt).getTime();
      const differenceInHours = Math.floor(
        (currentTimestamp - requestTimestamp) / (60 * 60 * 1000)
      );
      const index = differenceInHours % timePeriodInHours;
      acc[index].sum += (apiRequest.finishedAt - apiRequest.requestedAt) / 1000;
      acc[index].count += 1;
      acc[index].max = Math.max(
        acc[index].max,
        (apiRequest.finishedAt - apiRequest.requestedAt) / 1000
      );
      return acc;
    },
    new Array(timePeriodInHours)
      .fill(0)
      .map(() => ({ sum: 0, count: 0, max: 0 }))
  );

  function getAxisLabel(index: number) {
    const time = new Date();
    time.setHours(time.getHours() - (timePeriodInHours - index));

    return formatDateForChart(time);
  }

  const meanDataset = dataset.reverse().map((entry, hourIndex) => ({
    hourIndex,
    label: getAxisLabel(hourIndex),
    mean: entry.count > 0 ? entry.sum / entry.count : 0,
    max: entry.max,
    count: entry.count,
  }));

  return apiRequests.length === 0 ? (
    <NoDataContainer>
      No requests were made in the past {timePeriodInHours} hours.
    </NoDataContainer>
  ) : (
    <LineChart
      xAxis={[
        {
          dataKey: "hourIndex",
          valueFormatter: (index) => getAxisLabel(index),
        },
      ]}
      yAxis={[
        {
          id: "responseTime",
          label: "Response time (s)",
          min: 0,
          max: 1,
        },
        {
          id: "requestsCount",
          label: "Number of requests",
          min: 0,
          max: 100,
        },
      ]}
      series={[
        { yAxisId: "responseTime", dataKey: "mean", label: "mean" },
        { yAxisId: "responseTime", dataKey: "max", label: "max" },
        {
          yAxisId: "requestsCount",
          dataKey: "count",
          label: "number of requests",
        },
      ]}
      leftAxis="responseTime"
      rightAxis="requestsCount"
      dataset={meanDataset}
      grid={{ vertical: true, horizontal: true }}
      colors={[theme.textColor.highlight, "orange", theme.textColor.secondary]}
      sx={{
        "& .MuiMarkElement-root": {
          display: "none",
        },
        "& .MuiChartsLegend-series text": {
          fill: `${theme.textColor.default} !important`,
        },
        //change left yAxis label styles
        "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
          strokeWidth: "1",
          fill: theme.textColor.default,
        },
        "& .MuiChartsAxis-right .MuiChartsAxis-tickLabel": {
          strokeWidth: "1",
          fill: theme.textColor.default,
        },
        "& .MuiChartsAxis-label tspan": {
          fill: `${theme.textColor.default} !important`,
        },
        "& .MuiChartsAxis-tick": {
          stroke: theme.textColor.default,
        },
        // change bottom label styles
        "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
          strokeWidth: "1",
          fill: theme.textColor.default,
        },
        // bottomAxis Line Styles
        "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
          stroke: theme.textColor.default,
          strokeWidth: 1,
        },
        // leftAxis Line Styles
        "& .MuiChartsAxis-left .MuiChartsAxis-line": {
          stroke: theme.textColor.default,
          strokeWidth: 1,
        },
        "& .MuiChartsAxis-right .MuiChartsAxis-line": {
          stroke: theme.textColor.default,
          strokeWidth: 1,
        },
        "& .MuiChartsGrid-line": {
          stroke: theme.textColor.secondary,
        },
      }}
    />
  );
};

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${theme.textColor.secondary};
`;

import { PropsWithChildren } from "react";
import styled from "styled-components";
import { theme } from "../../theme";

export const Button = ({
  type = "button",
  disabled = false,
  onClick,
  children,
}: PropsWithChildren<{
  type?: "button" | "submit";
  disabled?: boolean;
  onClick?: () => void;
}>) => {
  return (
    <StyledButton type={type} onClick={onClick} disabled={disabled}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  background-color: ${theme.button.primary.backgroundColor};
  color: ${theme.button.primary.color};
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s;
  transition: color 0.5s;

  &:hover {
    background-color: #333;
    color: white;
  }
`;

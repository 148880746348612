import { useState } from "react";
import {
  ModuleFragment,
  useUpdateModuleSerialNumberMutation,
} from "../../../../data/generated";
import { Input } from "../../../../design-system/atoms/Input";
import { Modal } from "../../../../design-system/organisms/Modal";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Button } from "../../../../design-system/atoms/Button";

export const UpdateSerialNumberModal = ({
  module,
  onClose,
}: {
  module: ModuleFragment;
  onClose: () => void;
}) => {
  const [newSerialNumber, setNewSerialNumber] = useState("");

  const [updateModuleSerialNumberMutation] =
    useUpdateModuleSerialNumberMutation();

  async function updateModuleSerialNumber() {
    const { data } = await updateModuleSerialNumberMutation({
      variables: { serialNumber: module.serialNumber, newSerialNumber },
    });

    if (!data) {
      toast.error("An unexpected error occurred");
      return;
    }

    const {
      updateModuleSerialNumber: { success, error },
    } = data;

    if (error) {
      toast.error(error);
      return;
    }

    if (!success) {
      toast.error("An unexpected error occurred");
      return;
    }

    toast.success("Serial Number updated successfully");
    onClose();
  }

  return (
    <Modal title="Update Serial Number" onClose={onClose}>
      <ContentContainer>
        You're about to update the serial number of the module with current
        serial number {module.serialNumber}.
        <Input
          label="New Serial Number"
          value={newSerialNumber}
          onChange={(value) => setNewSerialNumber(value)}
        />
        <Button onClick={updateModuleSerialNumber}>Submit</Button>
      </ContentContainer>
    </Modal>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

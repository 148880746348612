import styled from "styled-components";
import { useGetModulesQuery } from "../../../data/generated";
import { theme } from "../../../theme";
import { ModuleDetailsRow } from "./ModuleDetailsRow";
import { AccessPoint } from "../../../design-system/atoms/icons/AccessPoint";
import { Loader } from "../../../design-system/atoms/Loader";

export const ModulesList = ({
  filters,
}: {
  filters: { moduleSerialNumber: string; powerBankSerialNumber: string };
}) => {
  const { data, loading, error, fetchMore } = useGetModulesQuery({
    variables: { limit: 50, offset: 0, filters },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return null;
  }

  if (loading || !data) {
    return <Loader />;
  }

  const {
    modules: { modules, hasMore },
  } = data;

  function handleScroll({ currentTarget }: React.UIEvent<HTMLDivElement>) {
    if (
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight
    ) {
      if (hasMore) {
        fetchMore({
          variables: {
            offset: modules.length,
            limit: 20,
            filters,
          },
        });
      }
    }
  }

  return (
    <Container onScroll={handleScroll}>
      <Table>
        <thead>
          <Tr>
            <Th>Serial Number</Th>
            <Th $isCentered>Status</Th>
            <Th $isCentered>Power banks</Th>
            <Th>Last seen at</Th>
            <Th $isCentered>
              <AccessPoint width={24} height={24} fillColor="white" />
            </Th>
            <Th>Firmware</Th>
            <Th>LAC</Th>
            <Th>ICCID</Th>
            <Th>CI</Th>
            <Th>IMEI</Th>
            <Th>Actions</Th>
          </Tr>
        </thead>
        <tbody>
          {modules.map((module) => (
            <ModuleDetailsRow
              key={module._id}
              module={module}
              powerBankSerialNumberFilter={filters.powerBankSerialNumber}
            />
          ))}
        </tbody>
      </Table>
      {modules.length === 0 && (
        <NoResultContainer>
          <p>No modules found</p>
        </NoResultContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  border: 1px solid ${theme.borderColor.highlight};
  border-radius: 10px;
`;

const Table = styled.table`
  min-width: 100%;
  max-height: 100%;
  overflow-x: auto;
`;

const Tr = styled.tr`
  background-color: ${theme.backgroundColor};
`;

const Th = styled.td<{ $isCentered?: boolean }>`
  position: sticky;
  top: 0px;
  background-color: ${theme.backgroundColor};
  font-weight: bold;
  text-align: ${({ $isCentered }) => ($isCentered ? "center" : "left")};
  padding: 5px;
`;

const NoResultContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${theme.textColor.secondary};
`;

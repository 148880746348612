import styled from "styled-components";
import { QueuedCommunicationFragment } from "../../../data/generated";
import { formatTimestamp } from "../../../utils/date";
import { theme } from "../../../theme";
import { CodeSample } from "../../../design-system/atoms/CodeSample";
import { IconButton } from "../../../design-system/atoms/IconButton";
import { useState } from "react";
import { CancelQueuedCommunicationModal } from "./CancelQueuedCommunicationModal";
import { Trash } from "../../../design-system/atoms/icons/Trash";

export const QueuedCommunicationDetailsRow = ({
  queuedCommunication,
}: {
  queuedCommunication: QueuedCommunicationFragment;
}) => {
  const [
    isCancelQueuedCommunicationModalOpen,
    setIsCancelQueuedCommunicationModalOpen,
  ] = useState(false);

  const { createdAt, moduleSerialNumber, command, data } = queuedCommunication;

  return (
    <Row>
      <Td>{moduleSerialNumber}</Td>
      <Td>
        <CellContent $hasMinHeight>{formatTimestamp(createdAt)}</CellContent>
      </Td>
      <Td>
        <CodeSample>{command}</CodeSample>
      </Td>
      <Td>
        <CodeSample>{data.join(", ")}</CodeSample>
      </Td>
      <Td>
        <IconButton
          title="Cancel queued communication"
          onClick={() => setIsCancelQueuedCommunicationModalOpen(true)}
          icon={<Trash width={20} height={20} fillColor="#333" />}
        />
      </Td>
      {isCancelQueuedCommunicationModalOpen ? (
        <CancelQueuedCommunicationModal
          queuedCommunication={queuedCommunication}
          onClose={() => setIsCancelQueuedCommunicationModalOpen(false)}
        />
      ) : null}
    </Row>
  );
};

const Row = styled.tr`
  padding: 5px;
`;

const Td = styled.td`
  color: ${theme.textColor.default};
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
`;

const CellContent = styled.div<{ $hasMinHeight?: boolean }>`
  display: flex;
  align-items: center;
  min-height: ${({ $hasMinHeight }) => ($hasMinHeight ? "39px" : 0)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

import styled from "styled-components";
import { OngoingCommunicationFragment } from "../../../data/generated";
import { formatTimestamp } from "../../../utils/date";
import { theme } from "../../../theme";
import { CodeSample } from "../../../design-system/atoms/CodeSample";

export const OngoingCommunicationDetailsRow = ({
  ongoingCommunication,
}: {
  ongoingCommunication: OngoingCommunicationFragment;
}) => {
  const { createdAt, orderedAt, moduleSerialNumber, command, data } =
    ongoingCommunication;

  return (
    <Row>
      <Td>{moduleSerialNumber}</Td>
      <Td>
        <CellContent $hasMinHeight>{formatTimestamp(orderedAt)}</CellContent>
      </Td>
      <Td>
        <CellContent $hasMinHeight>{formatTimestamp(createdAt)}</CellContent>
      </Td>
      <Td>
        <CodeSample>{command}</CodeSample>
      </Td>
      <Td>
        <CodeSample>{data.join(", ")}</CodeSample>
      </Td>
    </Row>
  );
};

const Row = styled.tr`
  padding: 5px;
`;

const Td = styled.td`
  color: ${theme.textColor.default};
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
`;

const CellContent = styled.div<{ $hasMinHeight?: boolean }>`
  display: flex;
  align-items: center;
  min-height: ${({ $hasMinHeight }) => ($hasMinHeight ? "39px" : 0)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

import styled from "styled-components";
import { theme } from "../../theme";

export const BatteryLevel = ({ batteryLevel }: { batteryLevel: number }) => {
  return (
    <Container>
      <Label>{batteryLevel}%</Label>
      <BatteryContainer>
        <BatteryFill $percentageWidth={batteryLevel} />
      </BatteryContainer>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;

const Label = styled.span`
  font-size: 10px;
`;

const BatteryContainer = styled.div`
  width: 30px;
  height: 10px;
  border: 1px solid ${theme.borderColor.default};
  padding: 1px;
  border-radius: 3px;
`;

const BatteryFill = styled.div<{ $percentageWidth: number }>`
  width: ${({ $percentageWidth }) => $percentageWidth}%;
  height: 100%;
  background-color: ${({ $percentageWidth }) =>
    $percentageWidth > 20 ? "#2bd853" : "#c51120"};
  border-radius: 1px;
`;

import { useApolloClient } from "@apollo/client";
import { GET_MODULE_AUTHORIZATIONS_QUERY } from "../data/queries/moduleAuthorization";
import {
  GetModuleAuthorizationsQuery,
  ModuleAuthorizationFragment,
  ModuleAuthorizationsFilters,
} from "../data/generated";
import {
  INITIAL_MODULE_AUTHORIZATIONS_LIMIT,
  INITIAL_MODULE_AUTHORIZATIONS_OFFSET,
} from "../constants/queries";

export const useModuleAuthorizationHandlers = () => {
  const apolloClient = useApolloClient();

  function handleNewModuleAuthorization({
    newModuleAuthorizations,
    filters,
  }: {
    newModuleAuthorizations: ModuleAuthorizationFragment[];
    filters: ModuleAuthorizationsFilters;
  }) {
    const data = apolloClient.readQuery<GetModuleAuthorizationsQuery>({
      query: GET_MODULE_AUTHORIZATIONS_QUERY,
      variables: {
        offset: INITIAL_MODULE_AUTHORIZATIONS_OFFSET,
        limit: INITIAL_MODULE_AUTHORIZATIONS_LIMIT,
        filters,
      },
    });

    if (!data) {
      return;
    }

    apolloClient.writeQuery<GetModuleAuthorizationsQuery>({
      query: GET_MODULE_AUTHORIZATIONS_QUERY,
      variables: {
        offset: INITIAL_MODULE_AUTHORIZATIONS_OFFSET,
        limit: INITIAL_MODULE_AUTHORIZATIONS_LIMIT,
        filters,
      },
      data: {
        moduleAuthorizations: {
          hasMore: data.moduleAuthorizations.hasMore,
          moduleAuthorizations: [
            ...newModuleAuthorizations,
            ...data.moduleAuthorizations.moduleAuthorizations,
          ],
        },
      },
    });
  }

  function handleDeletedModuleAuthorization({
    deletedModuleAuthorizations,
    filters,
  }: {
    deletedModuleAuthorizations: ModuleAuthorizationFragment[];
    filters: ModuleAuthorizationsFilters;
  }) {
    const data = apolloClient.readQuery<GetModuleAuthorizationsQuery>({
      query: GET_MODULE_AUTHORIZATIONS_QUERY,
      variables: {
        offset: INITIAL_MODULE_AUTHORIZATIONS_OFFSET,
        limit: INITIAL_MODULE_AUTHORIZATIONS_LIMIT,
        filters,
      },
    });

    if (!data) {
      return;
    }

    const deletedModuleAuthorizationIds = deletedModuleAuthorizations.map(
      ({ _id }) => _id
    );

    apolloClient.writeQuery<GetModuleAuthorizationsQuery>({
      query: GET_MODULE_AUTHORIZATIONS_QUERY,
      variables: {
        offset: INITIAL_MODULE_AUTHORIZATIONS_OFFSET,
        limit: INITIAL_MODULE_AUTHORIZATIONS_LIMIT,
        filters,
      },
      data: {
        moduleAuthorizations: {
          hasMore: data.moduleAuthorizations.hasMore,
          moduleAuthorizations: [
            ...data.moduleAuthorizations.moduleAuthorizations.filter(
              ({ _id }) => !deletedModuleAuthorizationIds.includes(_id)
            ),
          ],
        },
      },
    });
  }

  return { handleNewModuleAuthorization, handleDeletedModuleAuthorization };
};

import { useEffect, useState } from "react";
import { useLoginMutation } from "./data/generated";
import { Input } from "./design-system/atoms/Input";
import styled from "styled-components";
import { Button } from "./design-system/atoms/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const Login = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [login, { loading, error }] = useLoginMutation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await login({ variables: { username, password } });
      if (!result.data) {
        return null;
      }
      const {
        login: { error, token },
      } = result.data;

      if (token) {
        localStorage.setItem("token", token);
        navigate("/");
      }

      if (error) {
        toast.error(error);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        type="text"
        value={username}
        onChange={(value) => setUsername(value)}
        placeholder="Username"
      />
      <Input
        type="password"
        value={password}
        onChange={(value) => setPassword(value)}
        placeholder="Password"
      />
      <Button type="submit" disabled={loading}>
        Login
      </Button>
      {error && <p>{error.message}</p>}
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

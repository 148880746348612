import { Input } from "../../design-system/atoms/Input";

const formatDateTime = (timestamp: number | null): string => {
  if (!timestamp) {
    return "";
  }

  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const DatetimePicker = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value: number | null;
  onChange: (value: number | null) => void;
}) => {
  function onChangeCallback(value: string) {
    onChange(new Date(value).getTime());
  }

  return (
    <Input
      label={label}
      type="datetime-local"
      value={formatDateTime(value)}
      onChange={onChangeCallback}
    />
  );
};

import styled from "styled-components";
import { OngoingCommunicationsList } from "./components/OngoingCommunicationsList";
import { QueuedCommunicationsList } from "./components/QueuedCommunicationsList";
import { Title } from "../../design-system/atoms/Title";
import { Input } from "../../design-system/atoms/Input";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { updateSearchParam } from "../../utils/searchParams";
import { IconButton } from "../../design-system/atoms/IconButton";
import { FlushModuleQueuedCommunicationsModal } from "./components/FlushModuleQueuedCommunicationsModal";
import { Trash } from "../../design-system/atoms/icons/Trash";

export const MessageQueue = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<{
    moduleSerialNumber: string;
  }>({
    moduleSerialNumber: searchParams.get("moduleSerialNumber") || "",
  });
  const [
    isFlushModuleQueuedCommunicationsModalOpen,
    setIsFlushModuleQueuedCommunicationsModalOpen,
  ] = useState(false);

  useEffect(() => {
    const filterKeys = [
      { key: "moduleSerialNumber", value: filters.moduleSerialNumber },
    ];

    filterKeys.forEach(({ key, value }) => {
      updateSearchParam(searchParams, key, value);
    });

    setSearchParams(searchParams);
  }, [filters, searchParams, setSearchParams]);

  return (
    <Container>
      <Title>Message queue</Title>
      <FiltersContainer>
        <Input
          label="Module Serial Number"
          placeholder="12345678"
          value={filters.moduleSerialNumber}
          onChange={(value: string) =>
            setFilters({ ...filters, moduleSerialNumber: value })
          }
        />
        <IconButton
          title="Flush module queued communications"
          onClick={() => setIsFlushModuleQueuedCommunicationsModalOpen(true)}
          icon={<Trash width={24} height={24} fillColor="#333" />}
          isDisabled={!filters.moduleSerialNumber}
        />
      </FiltersContainer>
      <Panels>
        <Panel style={{ maxHeight: "50%" }}>
          <OngoingCommunicationsList filters={filters} />
        </Panel>
        <Panel style={{ flex: 1 }}>
          <QueuedCommunicationsList filters={filters} />
        </Panel>
      </Panels>
      {isFlushModuleQueuedCommunicationsModalOpen ? (
        <FlushModuleQueuedCommunicationsModal
          moduleSerialNumber={filters.moduleSerialNumber}
          onClose={() => setIsFlushModuleQueuedCommunicationsModalOpen(false)}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  overflow: hidden;
  padding: 15px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 10px;
`;

const Panels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
  overflow: hidden;
`;

const Panel = styled.div`
  overflow-x: scroll;
`;

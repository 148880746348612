import { toast } from "react-toastify";
import styled from "styled-components";
import { Modal } from "../../../design-system/organisms/Modal";
import { Button } from "../../../design-system/atoms/Button";
import { useFlushModuleQueuedCommunicationsMutation } from "../../../data/generated";

export const FlushModuleQueuedCommunicationsModal = ({
  moduleSerialNumber,
  onClose,
}: {
  moduleSerialNumber: string;
  onClose: () => void;
}) => {
  const [flushModuleQueuedCommunicationsMutation] =
    useFlushModuleQueuedCommunicationsMutation({
      variables: {
        moduleSerialNumber,
      },
    });

  async function flushModuleQueuedCommunications() {
    const { data } = await flushModuleQueuedCommunicationsMutation();

    if (!data) {
      toast.error("An unexpected error occurred");
      return;
    }

    const {
      flushModuleQueuedCommunications: { queuedCommunications, error },
    } = data;

    if (error) {
      toast.error(error);
      return;
    }

    // Todo, the queuedCommunications in cache should be updated with a subscription

    toast.success(
      `${queuedCommunications.length} communications cancelled successfully`
    );
    onClose();
  }

  return (
    <Modal title="Flush module queued communications" onClose={onClose}>
      <ContentContainer>
        You're about to cancel the queued communications of the module{" "}
        {moduleSerialNumber}. This will not cancel the ongoing communication as
        it's already sent.
        <Button onClick={flushModuleQueuedCommunications}>Confirm</Button>
      </ContentContainer>
    </Modal>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

import { colors } from "./palette";

export const theme = {
  backgroundColor: colors.darkgrey,
  modalBackgroundColor: colors.charcoalGrey,
  borderColor: {
    default: "lightgrey",
    highlight: colors.skyblue,
  },
  textColor: {
    default: colors.white,
    highlight: colors.skyblue,
    secondary: colors.grey,
  },
  button: {
    primary: {
      color: colors.darkgrey,
      backgroundColor: colors.skyblue,
      disabled: {
        backgroundColor: colors.grey,
      },
    },
  },
};

import styled from "styled-components";
import { Title } from "../../../design-system/atoms/Title";

export const DysfunctionsTile = () => {
  return (
    <Container>
      <Title>Dysfunctions</Title>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  padding: 15px;
`;

import styled from "styled-components";
import { Title } from "../../design-system/atoms/Title";
import { Input } from "../../design-system/atoms/Input";
import { useEffect, useState } from "react";
import { ModulesConnectivityList } from "./components/ModulesConnectivityList";
import { useSearchParams } from "react-router-dom";

export const ModulesConnectivityHistory = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [moduleSerialNumberFilter, setModuleSerialNumberFilter] = useState(
    () => searchParams.get("moduleSerialNumberFilter") || ""
  );

  useEffect(() => {
    if (moduleSerialNumberFilter) {
      searchParams.set("moduleSerialNumberFilter", moduleSerialNumberFilter);
    } else {
      searchParams.delete("moduleSerialNumberFilter");
    }
    setSearchParams(searchParams);
  }, [moduleSerialNumberFilter, searchParams, setSearchParams]);

  return (
    <Container>
      <Title>Modules connectivity history</Title>
      <FiltersContainer>
        <Input
          label="Module Serial Number"
          placeholder="12345678"
          value={moduleSerialNumberFilter}
          onChange={(value: string) => setModuleSerialNumberFilter(value)}
        />
      </FiltersContainer>
      <ModulesConnectivityList
        moduleSerialNumberFilter={moduleSerialNumberFilter}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  overflow: hidden;
  padding: 15px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

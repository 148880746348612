import { PropsWithChildren, useEffect, useRef, useState } from "react";
import styled from "styled-components";

export const CodeSample = ({ children }: PropsWithChildren) => {
  const [expanded, setExpanded] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      // Check if text overflows the container
      const hasOverflow =
        textRef.current.scrollWidth > textRef.current.clientWidth;
      setIsExpandable(hasOverflow);
    }
  }, []);

  return (
    <Container
      onClick={() => setExpanded(!expanded)}
      $isExpandable={isExpandable}
    >
      <StyledCode ref={textRef} $expanded={expanded}>
        {children}
      </StyledCode>
    </Container>
  );
};

const Container = styled.div<{ $isExpandable: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 100%;
  cursor: ${({ $isExpandable }) => ($isExpandable ? "pointer" : "default")};
`;

const StyledCode = styled.div<{ $expanded: boolean }>`
  font-family: "Fira Code", monospace;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${({ $expanded }) => ($expanded ? "normal" : "nowrap")};
  padding: 10px;
  background-color: #16181d;
  border-radius: 10px;
`;

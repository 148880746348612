import styled from "styled-components";
import { useGetModuleAuthorizationsQuery } from "../../../data/generated";
import { theme } from "../../../theme";
import { Loader } from "../../../design-system/atoms/Loader";
import { ModuleAuthorizationDetailsRow } from "./ModuleAuthorizationDetailsRow";
import {
  INITIAL_MODULE_AUTHORIZATIONS_LIMIT,
  INITIAL_MODULE_AUTHORIZATIONS_OFFSET,
} from "../../../constants/queries";

export const ModuleAuthorizationsList = ({
  moduleSerialNumberFilter,
}: {
  moduleSerialNumberFilter: string;
}) => {
  const { data, loading, error, fetchMore } = useGetModuleAuthorizationsQuery({
    variables: {
      limit: INITIAL_MODULE_AUTHORIZATIONS_LIMIT,
      offset: INITIAL_MODULE_AUTHORIZATIONS_OFFSET,
      filters: { moduleSerialNumber: moduleSerialNumberFilter },
    },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return null;
  }

  if (loading || !data) {
    return <Loader />;
  }

  const {
    moduleAuthorizations: { moduleAuthorizations, hasMore },
  } = data;

  function handleScroll({ currentTarget }: React.UIEvent<HTMLDivElement>) {
    if (
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight
    ) {
      if (hasMore) {
        fetchMore({
          variables: {
            offset: moduleAuthorizations.length,
            limit: 20,
            filters: { moduleSerialNumber: moduleSerialNumberFilter },
          },
        });
      }
    }
  }

  const sortedModuleAuthorizations = [...moduleAuthorizations].sort(
    (moduleAuthorizationA, moduleAuthorizationB) => {
      return moduleAuthorizationB.createdAt - moduleAuthorizationA.createdAt;
    }
  );

  return (
    <Container onScroll={handleScroll}>
      <Table>
        <thead>
          <Tr>
            <Th>Authorization date</Th>
            <Th>Module Serial Number</Th>
          </Tr>
        </thead>
        <tbody>
          {sortedModuleAuthorizations.map((moduleAuthorization) => (
            <ModuleAuthorizationDetailsRow
              key={moduleAuthorization._id}
              moduleAuthorization={moduleAuthorization}
            />
          ))}
        </tbody>
      </Table>
      {moduleAuthorizations.length === 0 && (
        <NoResultContainer>
          <p>No module authorizations</p>
        </NoResultContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  overflow-y: auto;
  border: 1px solid ${theme.borderColor.highlight};
  border-radius: 10px;
`;

const Table = styled.table`
  max-height: 100%;
  overflow-x: auto;
`;

const Tr = styled.tr`
  background-color: ${theme.backgroundColor};
`;

const Th = styled.td<{ $isCentered?: boolean }>`
  position: sticky;
  top: 0px;
  background-color: ${theme.backgroundColor};
  font-weight: bold;
  text-align: ${({ $isCentered }) => ($isCentered ? "center" : "left")};
  padding: 5px;
`;

const NoResultContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${theme.textColor.secondary};
`;

import styled from "styled-components";
import { ModuleFragment } from "../../../data/generated";
import { ClockEdit } from "../../../design-system/atoms/icons/ClockEdit";
import { Network } from "../../../design-system/atoms/icons/Network";
import { Numbers } from "../../../design-system/atoms/icons/Numbers";
import { PlayerEject } from "../../../design-system/atoms/icons/PlayerEject";
import { IconButton } from "../../../design-system/atoms/IconButton";
import { Refresh } from "../../../design-system/atoms/icons/Refresh";
import { useState } from "react";
import { UpdateSerialNumberModal } from "./modals/UpdateSerialNumberModal";
import { UpdateMiddlewareUrlModal } from "./modals/UpdateMiddlewareUrlModal";
import { UpdateIntervalsModal } from "./modals/UpdateIntervalsModal";
import { RestartModuleModal } from "./modals/RestartModuleModal";
import { EjectPowerBanksModal } from "./modals/EjectPowerBanksModal";

const ICON_SIZE = 20;

export const ModuleActions = ({ module }: { module: ModuleFragment }) => {
  const [isSerialNumberModalOpen, setIsSerialNumberModalOpen] = useState(false);
  const [isMiddlewareUrlModalOpen, setIsMiddlewareUrlModalOpen] =
    useState(false);
  const [isIntervalsModalOpen, setIsIntervalsModalOpen] = useState(false);
  const [isRestartModuleModalOpen, setIsRestartModuleModalOpen] =
    useState(false);
  const [isEjectPowerBanksModalOpen, setIsEjectPowerBanksModalOpen] =
    useState(false);

  const actions = [
    {
      label: "Update Serial Number",
      icon: <Numbers width={ICON_SIZE} height={ICON_SIZE} fillColor="#333" />,
      onClick: () => setIsSerialNumberModalOpen(true),
    },
    {
      label: "Update middleware URL",
      icon: <Network width={ICON_SIZE} height={ICON_SIZE} fillColor="#333" />,
      onClick: () => setIsMiddlewareUrlModalOpen(true),
    },
    {
      label: "Update intervals",
      icon: <ClockEdit width={ICON_SIZE} height={ICON_SIZE} fillColor="#333" />,
      onClick: () => setIsIntervalsModalOpen(true),
    },
    {
      label: "Restart",
      icon: <Refresh width={ICON_SIZE} height={ICON_SIZE} fillColor="#333" />,
      onClick: () => setIsRestartModuleModalOpen(true),
    },
    {
      label: "Pop out all power banks",
      icon: (
        <PlayerEject width={ICON_SIZE} height={ICON_SIZE} fillColor="#333" />
      ),
      onClick: () => setIsEjectPowerBanksModalOpen(true),
    },
  ];

  return (
    <Container>
      {actions.map(({ icon, label, onClick }, index) => (
        <IconButton key={index} title={label} onClick={onClick} icon={icon} />
      ))}
      {isSerialNumberModalOpen ? (
        <UpdateSerialNumberModal
          module={module}
          onClose={() => setIsSerialNumberModalOpen(false)}
        />
      ) : null}
      {isMiddlewareUrlModalOpen ? (
        <UpdateMiddlewareUrlModal
          module={module}
          onClose={() => setIsMiddlewareUrlModalOpen(false)}
        />
      ) : null}
      {isIntervalsModalOpen ? (
        <UpdateIntervalsModal
          module={module}
          onClose={() => setIsIntervalsModalOpen(false)}
        />
      ) : null}
      {isRestartModuleModalOpen ? (
        <RestartModuleModal
          module={module}
          onClose={() => setIsRestartModuleModalOpen(false)}
        />
      ) : null}
      {isEjectPowerBanksModalOpen ? (
        <EjectPowerBanksModal
          module={module}
          onClose={() => setIsEjectPowerBanksModalOpen(false)}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 5px;
`;

import styled from "styled-components";
import { LogsList } from "./components/LogsList";
import { Title } from "../../design-system/atoms/Title";
import { Input } from "../../design-system/atoms/Input";
import { useEffect, useState } from "react";
import { MessageCommand, MessageSender } from "../../data/generated";
import { Select } from "../../design-system/atoms/Select";
import { DatetimePicker } from "../../design-system/atoms/DatetimePicker";
import { useSearchParams } from "react-router-dom";
import {
  extractArrayFromSearchParams,
  updateSearchParam,
} from "../../utils/searchParams";
import { LogFilters } from "./types";

const moduleCommands = [
  {
    value: MessageCommand.ConfigureModuleConfirmation,
    label: "CS - Configure module confirmation",
  },
  {
    value: MessageCommand.PowerBankReturn,
    label: "RS - Power bank return",
  },
  {
    value: MessageCommand.SlotInformation,
    label: "CQ - Slot information",
  },
  {
    value: MessageCommand.ModuleInformation,
    label: "CN - Module information",
  },
  {
    value: MessageCommand.AllSlotsInformation,
    label: "AC - All slots information",
  },
  {
    value: MessageCommand.PowerBankLoanConfirmation,
    label: "FB - Power bank loan confirmation",
  },
  {
    value: MessageCommand.PowerBankEjectionConfirmation,
    label: "FA - Power bank ejection confirmation",
  },
];

const serverCommands = [
  {
    value: MessageCommand.ConfigureModule,
    label: "CS - Configure module",
  },
  {
    value: MessageCommand.EjectPowerBank,
    label: "FA - Eject power bank",
  },
  {
    value: MessageCommand.LendPowerBank,
    label: "FB - Lend power bank",
  },
  {
    value: MessageCommand.ConfirmPowerBankReturn,
    label: "RS - Confirm power bank return",
  },
  {
    value: MessageCommand.ConfirmModuleInformation,
    label: "CN - Confirm module information",
  },
  {
    value: MessageCommand.AcknowledgePowerBankLoanConfirmation,
    label: "BR - Acknowledge power bank loan confirmation",
  },
];

const commandOptions = [
  {
    label: "Commands from module",
    options: moduleCommands,
  },
  {
    label: "Commands from server",
    options: serverCommands,
  },
];

const senderOptions = [
  { value: MessageSender.Module, label: "Module" },
  { value: MessageSender.Server, label: "Server" },
];

export const Logs = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<LogFilters>({
    moduleSerialNumber: searchParams.get("moduleSerialNumber") || "",
    sender: (searchParams.get("sender") as MessageSender) || null,
    afterTimestamp: searchParams.get("afterTimestamp")
      ? Number(searchParams.get("afterTimestamp"))
      : null,
    beforeTimestamp: searchParams.get("beforeTimestamp")
      ? Number(searchParams.get("beforeTimestamp"))
      : null,
    commands: extractArrayFromSearchParams<MessageCommand>(
      searchParams,
      "commands"
    ),
    hiddenCommands: extractArrayFromSearchParams<MessageCommand>(
      searchParams,
      "hiddenCommands"
    ),
  });

  useEffect(() => {
    const filterKeys = [
      { key: "moduleSerialNumber", value: filters.moduleSerialNumber },
      { key: "sender", value: filters.sender },
      { key: "commands", value: filters.commands },
      { key: "hiddenCommands", value: filters.hiddenCommands },
      { key: "afterTimestamp", value: filters.afterTimestamp },
      { key: "beforeTimestamp", value: filters.beforeTimestamp },
    ];

    filterKeys.forEach(({ key, value }) => {
      updateSearchParam(searchParams, key, value);
    });

    setSearchParams(searchParams);
  }, [filters, searchParams, setSearchParams]);

  return (
    <Container>
      <Title>Logs</Title>
      <FiltersContainer>
        <Input
          label="Module Serial Number"
          placeholder="12345678"
          value={filters.moduleSerialNumber}
          onChange={(value: string) =>
            setFilters({ ...filters, moduleSerialNumber: value })
          }
        />
        <Select
          label="Sender"
          value={senderOptions.find(({ value }) => value === filters.sender)}
          options={[
            { value: MessageSender.Module, label: "Module" },
            { value: MessageSender.Server, label: "Server" },
          ]}
          onChange={(option) =>
            setFilters({ ...filters, sender: option?.value ?? null })
          }
          isClearable
        />
        <Select
          label="Commands"
          options={commandOptions}
          onChange={(options) =>
            setFilters({
              ...filters,
              commands: options ? options.map(({ value }) => value) : [],
            })
          }
          value={[...moduleCommands, ...serverCommands].filter(({ value }) =>
            filters.commands.includes(value)
          )}
          isClearable
          isMulti
          style={{ minWidth: 300 }}
        />
        <Select
          label="Hided Commands"
          options={commandOptions}
          onChange={(options) =>
            setFilters({
              ...filters,
              hiddenCommands: options ? options.map(({ value }) => value) : [],
            })
          }
          value={[...moduleCommands, ...serverCommands].filter(({ value }) =>
            filters.hiddenCommands.includes(value)
          )}
          isClearable
          isMulti
          style={{ minWidth: 300 }}
        />
        <DatetimePicker
          label="After date"
          value={filters.afterTimestamp}
          onChange={(value) =>
            setFilters({
              ...filters,
              afterTimestamp: value,
            })
          }
        />
        <DatetimePicker
          label="Before date"
          value={filters.beforeTimestamp}
          onChange={(value) =>
            setFilters({
              ...filters,
              beforeTimestamp: value,
            })
          }
        />
      </FiltersContainer>
      <LogsList filters={filters} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  overflow: hidden;
  padding: 15px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

import styled from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from "./Login";
import { AuthenticatedRoutes } from "./AuthenticatedRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

function App() {
  return (
    <div>
      <Header>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/*" element={<AuthenticatedRoutes />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </Header>
    </div>
  );
}

export default App;

const Header = styled.header`
  background-color: #282c34;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
`;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dashboard } from "./Dashboard";

export const AuthenticatedRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  return <Dashboard />;
};

import styled from "styled-components";
import { Title } from "../../design-system/atoms/Title";
import { ApiRequestsTile } from "./components/ApiRequestsTile";
import { ModulesTile } from "./components/ModulesTile";
import { DysfunctionsTile } from "./components/DysfunctionsTile";
import { LogsTile } from "./components/LogsTile";
import { theme } from "../../theme";
import { PowerBanksTile } from "./components/PowerBanksTile";

export const Home = () => {
  return (
    <Container>
      <Title>Home</Title>
      <PanelsContainer>
        <LeftPanel>
          <PanelTileContainer>
            <ModulesTile />
          </PanelTileContainer>
          <PanelTileContainer>
            <PowerBanksTile />
          </PanelTileContainer>
          <PanelTileContainer $flex={2}>
            <DysfunctionsTile />
          </PanelTileContainer>
        </LeftPanel>
        <RightPanel>
          <PanelTileContainer $flex={3}>
            <ApiRequestsTile />
          </PanelTileContainer>
          <PanelTileContainer $flex={2}>
            <LogsTile />
          </PanelTileContainer>
        </RightPanel>
      </PanelsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  padding: 15px;
  overflow: hidden;
`;

const PanelsContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  overflow: hidden;
`;

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  height: 100%;
  overflow: hidden;
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 3;
  height: 100%;
  overflow: hidden;
`;

const PanelTileContainer = styled.div<{ $flex?: number }>`
  display: flex;
  flex: ${({ $flex = "none" }) => $flex};
  overflow: hidden;
  border: 1px solid ${theme.borderColor.default};
  border-radius: 10px;
`;

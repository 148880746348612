import { useState } from "react";
import { Input } from "../../../../design-system/atoms/Input";
import { Modal } from "../../../../design-system/organisms/Modal";
import { Button } from "../../../../design-system/atoms/Button";
import { toast } from "react-toastify";
import {
  ModuleFragment,
  useUpdateModuleIntervalsMutation,
} from "../../../../data/generated";
import styled from "styled-components";

export const UpdateIntervalsModal = ({
  module,
  onClose,
}: {
  module: ModuleFragment;
  onClose: () => void;
}) => {
  const [heartbeatInterval, setHeartbeatInterval] = useState("");
  const [reportingInterval, setReportingInterval] = useState("");

  const [updateModuleIntervalsMutation] = useUpdateModuleIntervalsMutation();

  async function updateModuleIntervals() {
    const { data } = await updateModuleIntervalsMutation({
      variables: {
        serialNumber: module.serialNumber,
        heartbeatInterval: Number(heartbeatInterval),
        reportingInterval: Number(reportingInterval),
      },
    });

    if (!data) {
      toast.error("An unexpected error occurred");
      return;
    }

    const {
      updateModuleIntervals: { success, error },
    } = data;

    if (error) {
      toast.error(error);
      return;
    }

    if (!success) {
      toast.error("An unexpected error occurred");
      return;
    }

    toast.success("Intervals updated successfully");
    onClose();
  }

  return (
    <Modal title="Update module intervals" onClose={onClose}>
      <ContentContainer>
        You're about to update the heartbeat and reporting intervals for the
        module with Serial Number {module.serialNumber}.
        <Input
          type="number"
          label="Heartbeat interval (in seconds)"
          value={heartbeatInterval}
          onChange={(value) => setHeartbeatInterval(value)}
        />
        <Input
          type="number"
          label="Reporting interval (in seconds)"
          value={reportingInterval}
          onChange={(value) => setReportingInterval(value)}
        />
        <Button onClick={updateModuleIntervals}>Submit</Button>
      </ContentContainer>
    </Modal>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

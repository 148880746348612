import ReactSelect, { GroupBase, Props } from "react-select";
import styled, { CSSProperties } from "styled-components";
import { theme } from "../../theme";

export const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  label,
  style,
  ...props
}: Props<Option, IsMulti, Group> & {
  label: string;
  style?: CSSProperties;
}) => {
  return (
    <Container style={style}>
      {label ? <Label>{label}</Label> : null}
      <ReactSelect
        {...props}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused
              ? theme.borderColor.highlight
              : theme.borderColor.default,
            backgroundColor: "#343a46",
            color: theme.textColor.default,
            boxShadow: "none",

            ":hover": {
              ...baseStyles[":hover"],
              borderWidth: 1,
              borderColor: theme.borderColor.highlight,
            },
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: "#343a46",
            color: theme.textColor.default,
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: theme.textColor.default,
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused
              ? theme.backgroundColor
              : "#343a46",
            color: theme.textColor.default,
          }),
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: default;
`;

const Label = styled.span`
  font-size: 14px;
`;

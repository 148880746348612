import { Link, Route, Routes } from "react-router-dom";
import { Modules } from "./pages/modules";
import { Home } from "./pages/home/Home";
import styled from "styled-components";
import { theme } from "./theme";
import { ModulesConnectivityHistory } from "./pages/modulesConnectivityHistory";
import { Logs } from "./pages/logs";
import { ModuleAuthorizations } from "./pages/moduleAuthorizations";
import { WebhookRequests } from "./pages/webhookRequests";
import { PowerBanks } from "./pages/powerBanks";
import { ApiRequests } from "./pages/apiRequests";
import { MessageQueue } from "./pages/messageQueue";

const routes = [
  {
    path: "/",
    element: <Home />,
    label: "Home",
  },
  {
    path: "/modules",
    element: <Modules />,
    label: "Modules",
  },
  {
    path: "/power-banks",
    element: <PowerBanks />,
    label: "Power banks",
  },
  {
    path: "/modules-connectivity-history",
    element: <ModulesConnectivityHistory />,
    label: "Modules connectivity history",
  },
  {
    path: "/module-authorizations",
    element: <ModuleAuthorizations />,
    label: "Module authorizations",
  },
  {
    path: "/message-queue",
    element: <MessageQueue />,
    label: "Message queue",
  },
  {
    path: "/logs",
    element: <Logs />,
    label: "Logs",
  },
  {
    path: "/api-requests",
    element: <ApiRequests />,
    label: "API requests",
  },
  {
    path: "/webhook-requests",
    element: <WebhookRequests />,
    label: "Webhook requests",
  },
];
export const Dashboard = () => {
  return (
    <Container>
      <NavBar>
        {routes.map((route, index) => (
          <NavBarItem
            key={index}
            to={route.path}
            $isActive={window.location.pathname === route.path}
          >
            {route.label}
          </NavBarItem>
        ))}
      </NavBar>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 200px;
  background-color: ${theme.modalBackgroundColor};
  padding: 10px;
`;

const NavBarItem = styled(Link)<{ $isActive: boolean }>`
  color: ${({ $isActive }) =>
    $isActive ? theme.textColor.highlight : theme.textColor.default};
  text-decoration: none;
  padding: 10px;
  border-radius: 10px;
  background-color: ${({ $isActive }) =>
    $isActive ? "#2c3541" : theme.modalBackgroundColor};

  &:hover {
    background-color: #2f323a;
  }
`;

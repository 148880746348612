import styled from "styled-components";
import { theme } from "../../theme";

export const Textarea = ({
  label,
  sublabel,
  placeholder,
  value,
  onChange,
}: {
  label?: string;
  sublabel?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <Container>
      {label ? <Label>{label}</Label> : null}
      <StyledTextarea
        placeholder={placeholder}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
          onChange(event.target.value)
        }
      />
      {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Label = styled.span`
  font-size: 14px;
`;

const Sublabel = styled.span`
  font-size: 12px;
  color: ${theme.textColor.secondary};
`;

const StyledTextarea = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  background-color: #343a46;
  outline: none;
  color: white;
  caret-color: ${theme.textColor.highlight};
  color-scheme: dark;

  &:focus {
    border: 1px solid ${theme.borderColor.highlight};
  }
`;

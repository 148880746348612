import styled from "styled-components";
import { Title } from "../../../design-system/atoms/Title";
import { useState } from "react";
import { Input } from "../../../design-system/atoms/Input";
import { ApiRequestsChart } from "./ApiRequestsChart";

const DEFAULT_TIME_PERIOD_IN_HOURS = 24;

export const ApiRequestsTile = () => {
  const [timePeriodInHours, setTimePeriodInHours] = useState(
    DEFAULT_TIME_PERIOD_IN_HOURS
  );

  return (
    <Container>
      <Header>
        <Title>API requests (last {timePeriodInHours} hours)</Title>
        <Input
          type="number"
          label="Show last ... hours"
          value={timePeriodInHours.toString()}
          onChange={(value) => setTimePeriodInHours(Number(value))}
        />
      </Header>
      <ChartContainer>
        <ApiRequestsChart timePeriodInHours={timePeriodInHours} />
      </ChartContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ChartContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

import styled from "styled-components";
import { ModuleFragment } from "../../../data/generated";
import { formatTimestamp } from "../../../utils/date";
import { EyePlus } from "../../../design-system/atoms/icons/EyePlus";
import { useState } from "react";
import { BatteryLevel } from "../../../design-system/atoms/BatteryLevel";
import { theme } from "../../../theme";
import { ModuleActions } from "./ModuleActions";
import { History } from "../../../design-system/atoms/icons/History";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../../../design-system/atoms/IconButton";
import { PlayerEject } from "../../../design-system/atoms/icons/PlayerEject";
import { EjectPowerBankModal } from "./modals/EjectPowerBankModal";

export const ModuleDetailsRow = ({
  module,
  powerBankSerialNumberFilter,
}: {
  module: ModuleFragment;
  powerBankSerialNumberFilter: string;
}) => {
  const navigate = useNavigate();

  const [areSlotsExpanded, setAreSlotsExpanded] = useState(
    !!powerBankSerialNumberFilter
  );
  const [powerBankSlotIndexToUnlock, setPowerBankSlotIndexToUnlock] = useState<
    number | null
  >(null);

  const {
    serialNumber,
    slots,
    lastSeenAt,
    firmwareVersion,
    isOnline,
    signalIntensity,
    LAC,
    ICCID,
    CI,
    IMEI,
  } = module;

  const numberOfSlots = slots.length;
  const slotsWithPowerBanks = slots.filter((slot) => slot.powerBank);
  const numberOfPowerBanks = slotsWithPowerBanks.length;
  const lastSeenAtDate = formatTimestamp(lastSeenAt);
  const mightBeOffline = lastSeenAt < new Date().getTime() - 3 * 60 * 1000;

  return (
    <>
      <tr>
        <Td>{serialNumber}</Td>
        <Td $isCentered>
          <StatusDotContainer
            title="Go to connectivity history"
            onClick={() =>
              navigate(
                `/modules-connectivity-history?moduleSerialNumberFilter=${serialNumber}`
              )
            }
          >
            <StatusDot $isOnline={isOnline} $mightBeOffline={mightBeOffline} />
            <History width={20} height={20} fillColor="white" />
          </StatusDotContainer>
        </Td>
        <Td $isCentered>
          <SlotsExpandButton
            onClick={() => setAreSlotsExpanded(!areSlotsExpanded)}
          >
            {numberOfPowerBanks}/{numberOfSlots}{" "}
            <EyePlus width={20} height={20} fillColor="white" />
          </SlotsExpandButton>
        </Td>
        <Td>
          {lastSeenAtDate}{" "}
          <Emoji title="The module was last seen more than 3 minutes ago.">
            {mightBeOffline ? "⚠️" : ""}
          </Emoji>
        </Td>
        <Td $isCentered>{signalIntensity}</Td>
        <Td>{firmwareVersion}</Td>
        <Td>{LAC}</Td>
        <Td>{ICCID}</Td>
        <Td>{CI}</Td>
        <Td>{IMEI}</Td>
        <Td>
          <ModuleActions module={module} />
        </Td>
      </tr>
      {areSlotsExpanded ? (
        <tr>
          <Td colSpan={10}>
            <SlotsTable>
              <thead>
                <tr>
                  <Th $isCentered>Slot</Th>
                  <Th>Power bank</Th>
                  <Th>Battery level</Th>
                  <Th>Actions</Th>
                </tr>
              </thead>
              <tbody>
                {slots.map((slot, slotIndex) => {
                  const powerBankSerialNumber = slot.powerBank?.serialNumber;

                  return (
                    <tr key={slot._id}>
                      <Td $isCentered>{slotIndex + 1}</Td>
                      <Td
                        $isSecondary={!slot.powerBank}
                        $isHighlighted={
                          !!slot.powerBank &&
                          !!powerBankSerialNumberFilter &&
                          slot.powerBank.serialNumber.includes(
                            powerBankSerialNumberFilter
                          )
                        }
                      >
                        {slot.powerBank ? slot.powerBank.serialNumber : "EMPTY"}
                      </Td>
                      <Td>
                        {slot.powerBank ? (
                          <BatteryLevel
                            batteryLevel={slot.powerBank.batteryLevel}
                          />
                        ) : null}
                      </Td>
                      <Td>
                        {powerBankSerialNumber ? (
                          <IconButton
                            title="Eject power bank"
                            onClick={() =>
                              setPowerBankSlotIndexToUnlock(slotIndex + 1)
                            }
                            icon={
                              <PlayerEject
                                width={20}
                                height={20}
                                fillColor="#333"
                              />
                            }
                          />
                        ) : null}
                      </Td>
                    </tr>
                  );
                })}
              </tbody>
            </SlotsTable>
          </Td>
        </tr>
      ) : null}
      {powerBankSlotIndexToUnlock ? (
        <EjectPowerBankModal
          moduleSerialNumber={module.serialNumber}
          slotIndex={powerBankSlotIndexToUnlock}
          onClose={() => setPowerBankSlotIndexToUnlock(null)}
        />
      ) : null}
    </>
  );
};

const Th = styled.th<{ $isCentered?: boolean }>`
  font-weight: bold;
  text-align: ${({ $isCentered }) => ($isCentered ? "center" : "left")};
  padding: 5px;
`;

const Td = styled.td<{
  $isCentered?: boolean;
  $isSecondary?: boolean;
  $isHighlighted?: boolean;
}>`
  text-align: ${({ $isCentered }) => ($isCentered ? "center" : "left")};
  padding: 5px;
  color: ${({ $isHighlighted, $isSecondary }) =>
    $isHighlighted
      ? theme.textColor.highlight
      : $isSecondary
      ? theme.textColor.secondary
      : theme.textColor.default};
`;

const Emoji = styled.span`
  font-family: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif;
  font-size: 14px;
`;

const SlotsTable = styled.table`
  border-left: 2px solid ${theme.borderColor.default};
  margin-left: 30px;
`;

const SlotsExpandButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;
  gap: 10px;
  cursor: pointer;
`;

const StatusDotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 5px;
  cursor: pointer;
`;

const StatusDot = styled.div<{ $isOnline: boolean; $mightBeOffline: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ $isOnline, $mightBeOffline }) =>
    $isOnline ? ($mightBeOffline ? "#ff8c00" : "#2bd853") : "#c51120"};
`;

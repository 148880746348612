import { useState } from "react";
import { Modal } from "../../../design-system/organisms/Modal";
import { Button } from "../../../design-system/atoms/Button";
import styled from "styled-components";
import { Textarea } from "../../../design-system/atoms/Textarea";

export const NewModuleAuthorizationModal = ({
  createModuleAuthorizations,
  onClose,
}: {
  createModuleAuthorizations: ({
    moduleSerialNumbers,
  }: {
    moduleSerialNumbers: string[];
  }) => void;
  onClose: () => void;
}) => {
  const [moduleSerialNumbersString, setModuleSerialNumbersString] =
    useState("");

  const moduleSerialNumbers = moduleSerialNumbersString
    .replace(" ", "")
    .split(",")
    .filter((serialNumber) => serialNumber);

  async function submit() {
    createModuleAuthorizations({ moduleSerialNumbers });
  }

  return (
    <Modal title="Add new module authorization" onClose={onClose}>
      <ContentContainer>
        Enter the serial numbers of the modules you want to authorize, separated
        by commas.
        <Textarea
          label="Module Serial Numbers"
          placeholder="01234,56789,12345"
          onChange={setModuleSerialNumbersString}
          value={moduleSerialNumbersString}
        />
        <Button onClick={submit}>
          Authorize
          {moduleSerialNumbersString
            ? ` ${moduleSerialNumbers.length} module${
                moduleSerialNumbers.length > 1 ? "s" : ""
              }`
            : ""}
        </Button>
      </ContentContainer>
    </Modal>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

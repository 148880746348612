import { gql } from "@apollo/client";
import { MODULE_AUTHORIZATION_FRAGMENT } from "../fragments/moduleAuthorization";

export const GET_MODULE_AUTHORIZATIONS_QUERY = gql`
  query GetModuleAuthorizations(
    $limit: Int!
    $offset: Int!
    $filters: ModuleAuthorizationsFilters!
  ) {
    moduleAuthorizations(limit: $limit, offset: $offset, filters: $filters) {
      moduleAuthorizations {
        ...ModuleAuthorization
      }
      hasMore
    }
  }
  ${MODULE_AUTHORIZATION_FRAGMENT}
`;

import styled from "styled-components";
import { MessageFragment, MessageSender } from "../../../data/generated";
import { formatTimestamp } from "../../../utils/date";
import { theme } from "../../../theme";
import { MessageReply } from "../../../design-system/atoms/icons/MessageReply";
import { MessageForward } from "../../../design-system/atoms/icons/MessageForward";
import { CodeSample } from "../../../design-system/atoms/CodeSample";

export const LogDetailsRow = ({ message }: { message: MessageFragment }) => {
  const { createdAt, sender, topic, body } = message;

  return (
    <Row>
      <Cell style={{ width: 170 }}>
        <CellContent $hasMinHeight>{formatTimestamp(createdAt)}</CellContent>
      </Cell>
      <Cell style={{ width: 100 }}>
        <CellContent $hasMinHeight>
          <SenderContainer>
            {sender === MessageSender.Module ? (
              <MessageReply
                width={24}
                height={24}
                fillColor={theme.textColor.highlight}
              />
            ) : (
              <MessageForward width={24} height={24} fillColor={"#7af538"} />
            )}
            <span>{sender}</span>
          </SenderContainer>
        </CellContent>
      </Cell>
      <Cell style={{ width: 300 }}>
        <CodeSample>{topic}</CodeSample>
      </Cell>
      <Cell style={{ flex: 1 }}>
        <CodeSample>{body.replaceAll(",", ", ")}</CodeSample>
      </Cell>
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px;
`;

const Cell = styled.div`
  color: ${theme.textColor.default};
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
`;

const CellContent = styled.div<{ $hasMinHeight?: boolean }>`
  display: flex;
  align-items: center;
  min-height: ${({ $hasMinHeight }) => ($hasMinHeight ? "39px" : 0)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SenderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

import { toast } from "react-toastify";
import styled from "styled-components";
import { Modal } from "../../../design-system/organisms/Modal";
import { Button } from "../../../design-system/atoms/Button";
import {
  QueuedCommunication,
  useCancelQueuedCommunicationMutation,
} from "../../../data/generated";

export const CancelQueuedCommunicationModal = ({
  queuedCommunication,
  onClose,
}: {
  queuedCommunication: QueuedCommunication;
  onClose: () => void;
}) => {
  const [cancelQueuedCommunicationMutation] =
    useCancelQueuedCommunicationMutation({
      variables: {
        queuedCommunicationId: queuedCommunication._id,
      },
    });

  async function cancelQueuedCommunication() {
    const { data } = await cancelQueuedCommunicationMutation();

    if (!data) {
      toast.error("An unexpected error occurred");
      return;
    }

    const {
      cancelQueuedCommunication: { queuedCommunications, error },
    } = data;

    if (error) {
      toast.error(error);
      return;
    }

    if (!queuedCommunications.length) {
      toast.error("An unexpected error occurred");
    }

    // Todo, the queuedCommunications in cache should be updated with a subscription

    toast.success("Communication cancelled successfully");
    onClose();
  }

  return (
    <Modal title="Cancel queued communication" onClose={onClose}>
      <ContentContainer>
        You're about to cancel the queued communication on the module{" "}
        {queuedCommunication.moduleSerialNumber} with the command{" "}
        {queuedCommunication.command} and the data{" "}
        {queuedCommunication.data.join(", ")}.
        <Button onClick={cancelQueuedCommunication}>Confirm</Button>
      </ContentContainer>
    </Modal>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

import styled from "styled-components";
import { PowerBankFragment } from "../../../data/generated";
import { theme } from "../../../theme";
import { BatteryLevel } from "../../../design-system/atoms/BatteryLevel";
import { IconButton } from "../../../design-system/atoms/IconButton";
import { PlayerEject } from "../../../design-system/atoms/icons/PlayerEject";
import { useState } from "react";
import { EjectPowerBankModal } from "../../modules/components/modals/EjectPowerBankModal";

export const PowerBankDetailsRow = ({
  powerBank,
}: {
  powerBank: PowerBankFragment;
}) => {
  const [isEjectPowerBankModalOpen, setIsEjectPowerBankModalOpen] =
    useState(false);

  const {
    batteryLevel,
    serialNumber,
    moduleSerialNumber,
    transactionId,
    slotIndex,
  } = powerBank;

  return (
    <tr>
      <Td>{serialNumber}</Td>
      <Td>
        <BatteryLevel batteryLevel={batteryLevel} />
      </Td>
      <Td $isSecondary={!moduleSerialNumber}>
        {moduleSerialNumber ?? (transactionId ? "IN A TRANSACTION" : "NONE")}
      </Td>
      <Td $isSecondary={!slotIndex}>
        {slotIndex ?? (transactionId ? "IN A TRANSACTION" : "NONE")}
      </Td>
      <Td $isSecondary={!transactionId}>{transactionId ?? "NONE"}</Td>
      <Td>
        {moduleSerialNumber && slotIndex ? (
          <IconButton
            title="Eject power bank"
            onClick={() => setIsEjectPowerBankModalOpen(true)}
            icon={<PlayerEject width={20} height={20} fillColor="#333" />}
          />
        ) : null}
      </Td>
      {isEjectPowerBankModalOpen && moduleSerialNumber && slotIndex ? (
        <EjectPowerBankModal
          moduleSerialNumber={moduleSerialNumber}
          slotIndex={slotIndex}
          onClose={() => setIsEjectPowerBankModalOpen(false)}
        />
      ) : null}
    </tr>
  );
};

const Td = styled.td<{
  $isCentered?: boolean;
  $isSecondary?: boolean;
  $isHighlighted?: boolean;
}>`
  text-align: ${({ $isCentered }) => ($isCentered ? "center" : "left")};
  padding: 5px;
  color: ${({ $isHighlighted, $isSecondary }) =>
    $isHighlighted
      ? theme.textColor.highlight
      : $isSecondary
      ? theme.textColor.secondary
      : theme.textColor.default};
`;

import styled from "styled-components";
import { useGetPowerBanksInfoQuery } from "../../../../data/generated";
import { Loader } from "../../../../design-system/atoms/Loader";
import { theme } from "../../../../theme";

export const PowerBanksInfo = () => {
  const { data, loading } = useGetPowerBanksInfoQuery();

  if (loading || !data) {
    return <Loader />;
  }

  const {
    powerBanksInfo: { totalCount, loanedCount },
  } = data;

  return (
    <Container>
      <ValueContainer>
        <Count>{totalCount}</Count>
        <Label>power banks</Label>
      </ValueContainer>
      <ValueContainer>
        <Count>{loanedCount}</Count>
        <Label>loaned</Label>
      </ValueContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  height: 100%;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Count = styled.span`
  font-size: 4vh;
  font-weight: 600;
  color: ${theme.textColor.default};
`;

const Label = styled.span`
  font-size: 2vh;
  color: ${theme.textColor.secondary};
`;

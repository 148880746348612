import styled from "styled-components";
import {
  useGetApiRequestsQuery,
  ApiRequestsFilters,
} from "../../../data/generated";
import { theme } from "../../../theme";
import { ApiRequestDetailsRow } from "./ApiRequestDetailsRow";
import { Loader } from "../../../design-system/atoms/Loader";

export const ApiRequestsList = ({
  filters,
}: {
  filters: ApiRequestsFilters;
}) => {
  const { data, loading, error, fetchMore } = useGetApiRequestsQuery({
    variables: { limit: 50, offset: 0, filters },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return null;
  }

  if (loading || !data) {
    return <Loader />;
  }

  const {
    apiRequests: { hasMore, apiRequests },
  } = data;

  function handleScroll({ currentTarget }: React.UIEvent<HTMLDivElement>) {
    if (
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight
    ) {
      if (hasMore) {
        fetchMore({
          variables: {
            offset: apiRequests.length,
            limit: 50,
            filters,
          },
        });
      }
    }
  }

  return (
    <Container>
      <Table onScroll={handleScroll}>
        <HeaderRow>
          <HeaderCell style={{ width: 300 }}>Type</HeaderCell>
          <HeaderCell style={{ width: 100 }}>Origin</HeaderCell>
          <HeaderCell style={{ width: 70 }}>Status code</HeaderCell>
          <HeaderCell style={{ width: 170 }}>Requested at</HeaderCell>
          <HeaderCell style={{ width: 170 }}>Finished at</HeaderCell>
          <HeaderCell style={{ flex: 1 }}>Body</HeaderCell>
        </HeaderRow>
        {apiRequests.length === 0 ? (
          <NoResultContainer>
            <p>No api request</p>
          </NoResultContainer>
        ) : (
          apiRequests.map((apiRequest) => (
            <ApiRequestDetailsRow
              key={apiRequest._id}
              apiRequest={apiRequest}
            />
          ))
        )}
      </Table>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  border: 1px solid ${theme.borderColor.highlight};
  border-radius: 10px;
  overflow: hidden;
  z-index: 0;
`;

const Table = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const HeaderRow = styled.div`
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 10px;
  background-color: ${theme.backgroundColor};
  z-index: 1;
`;

const HeaderCell = styled.div`
  padding: 5px;
  color: ${theme.textColor.default};
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  font-weight: bold;
`;

const NoResultContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${theme.textColor.secondary};
`;

import styled from "styled-components";
import { MessageSender, useGetMessagesQuery } from "../../../../data/generated";
import { Loader } from "../../../../design-system/atoms/Loader";
import { formatTimestamp } from "../../../../utils/date";
import { MessageReply } from "../../../../design-system/atoms/icons/MessageReply";
import { MessageForward } from "../../../../design-system/atoms/icons/MessageForward";
import { theme } from "../../../../theme";
import { useNavigate } from "react-router-dom";
import { CodeSample } from "../../../../design-system/atoms/CodeSample";

export const LogsPreview = () => {
  const navigate = useNavigate();

  const { data, loading } = useGetMessagesQuery({
    variables: {
      limit: 20,
      offset: 0,
      filters: { moduleSerialNumber: "", commands: [] },
    },
  });

  if (loading || !data) {
    return <Loader />;
  }

  const {
    messages: { messages },
  } = data;

  return (
    <Container>
      <TableContainer>
        <Table>
          <HeaderRow>
            <HeaderCell style={{ width: 170 }}>Send date</HeaderCell>
            <HeaderCell style={{ width: 100 }}>Sender</HeaderCell>
            <HeaderCell style={{ width: 300 }}>Topic</HeaderCell>
            <HeaderCell style={{ flex: 1 }}>Message</HeaderCell>
          </HeaderRow>
          {messages.map((message) => (
            <Row key={message._id}>
              <Cell style={{ width: 170 }}>
                {formatTimestamp(message.createdAt)}
              </Cell>
              <Cell style={{ width: 100 }}>
                <SenderContainer>
                  {message.sender === MessageSender.Module ? (
                    <MessageReply
                      width={24}
                      height={24}
                      fillColor={theme.textColor.highlight}
                    />
                  ) : (
                    <MessageForward
                      width={24}
                      height={24}
                      fillColor={"#7af538"}
                    />
                  )}
                  <span>{message.sender}</span>
                </SenderContainer>
              </Cell>
              <Cell style={{ width: 300 }}>
                <CodeSample>{message.topic}</CodeSample>
              </Cell>
              <Cell style={{ flex: 1 }}>
                <CodeSample>{message.body}</CodeSample>
              </Cell>
            </Row>
          ))}
        </Table>
        <Gradient />
      </TableContainer>
      <SeeMoreContainer onClick={() => navigate("/logs")}>
        <span>See more</span>
      </SeeMoreContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const TableContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  overflow: hidden;
`;

const Table = styled.div`
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 10px;
  background-color: ${theme.backgroundColor};
  z-index: 1;
`;

const HeaderCell = styled.div`
  padding: 5px;
  color: ${theme.textColor.default};
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px;
`;

const Cell = styled.div`
  color: ${theme.textColor.default};
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
`;

const SenderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Gradient = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 1;
  height: 100px;
  background: linear-gradient(to bottom, transparent, ${theme.backgroundColor});
`;

const SeeMoreContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
  z-index: 2;
  cursor: pointer;
  color: ${theme.textColor.highlight};
`;

import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  ApolloLink,
  ServerError,
} from "@apollo/client";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import {
  MessagesResponse,
  ModulesResponse,
  ModuleAuthorizationsResponse,
  ModuleConnectivityStatesResponse,
} from "./data/generated";
import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

// for now it's cleaner to just get the react app env to compute the api url instead of passing the api url as parameters
function getApiUrl() {
  switch (process.env.REACT_APP_ENV) {
    case "development":
      return "https://api.oneflash.enigmo-games-freelance.com/graphql";
    case "production":
      return "https://api.oneflash.enigmo-games-freelance.com/graphql";
    case "enigmo":
      return "https://api.oneflash.enigmo-games.com/graphql";
    case "local":
    default:
      return "http://localhost:4000/graphql";
  }
}

function getWebSocketApiUrl() {
  switch (process.env.REACT_APP_ENV) {
    case "development":
      return "wss://api.oneflash.enigmo-games-freelance.com/subscriptions";
    case "production":
      return "wss://api.oneflash.enigmo-games-freelance.com/subscriptions";
    case "enigmo":
      return "wss://api.oneflash.enigmo-games.com/subscriptions";
    case "local":
    default:
      return "ws://localhost:4000/subscriptions";
  }
}

const httpLink = new HttpLink({
  uri: getApiUrl(),
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token"); // Retrieve token from localStorage
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    if ((networkError as ServerError).statusCode === 403) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }

    console.log(`[Network error]: ${networkError}`);
  }
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: getWebSocketApiUrl(),
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const link = ApolloLink.from([authLink, errorLink, splitLink]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          messages: {
            keyArgs: ["filters"],
            merge(
              existing: MessagesResponse | undefined,
              incoming: MessagesResponse,
              { readField }: { readField: ReadFieldFunction }
            ) {
              if (!existing) {
                return incoming;
              }

              const newMessages =
                incoming.messages.filter((message) =>
                  existing.messages.every(
                    (existingMessage) =>
                      readField("_id", existingMessage) !==
                      readField("_id", message)
                  )
                ) ?? [];

              return {
                ...existing,
                ...incoming,
                messages: [...(existing.messages ?? []), ...newMessages],
              };
            },
          },
          modules: {
            keyArgs: ["filters"],
            merge(
              existing: ModulesResponse | undefined,
              incoming: ModulesResponse,
              { readField }: { readField: ReadFieldFunction }
            ) {
              if (!existing) {
                return incoming;
              }

              const newModules =
                incoming.modules.filter((module) =>
                  existing.modules.every(
                    (existingModule) =>
                      readField("_id", existingModule) !==
                      readField("_id", module)
                  )
                ) ?? [];

              return {
                ...existing,
                ...incoming,
                modules: [...(existing.modules ?? []), ...newModules],
              };
            },
          },
          moduleAuthorizations: {
            keyArgs: ["filters"],
            merge(
              existing: ModuleAuthorizationsResponse | undefined,
              incoming: ModuleAuthorizationsResponse,
              { readField }: { readField: ReadFieldFunction }
            ) {
              if (!existing) {
                return incoming;
              }

              const newModuleAuthorizations =
                incoming.moduleAuthorizations.filter((moduleAuthorization) =>
                  existing.moduleAuthorizations.every(
                    (existingModuleAuthorization) =>
                      readField("_id", existingModuleAuthorization) !==
                      readField("_id", moduleAuthorization)
                  )
                ) ?? [];

              return {
                ...existing,
                ...incoming,
                moduleAuthorizations: [
                  ...(existing.moduleAuthorizations ?? []),
                  ...newModuleAuthorizations,
                ],
              };
            },
          },
          moduleConnectivityStates: {
            keyArgs: ["filters"],
            merge(
              existing: ModuleConnectivityStatesResponse | undefined,
              incoming: ModuleConnectivityStatesResponse,
              { readField }: { readField: ReadFieldFunction }
            ) {
              if (!existing) {
                return incoming;
              }

              const newModuleConnectivityStates =
                incoming.moduleConnectivityStates.filter(
                  (moduleConnectivityState) =>
                    existing.moduleConnectivityStates.every(
                      (existingModuleConnectivityState) =>
                        readField("_id", existingModuleConnectivityState) !==
                        readField("_id", moduleConnectivityState)
                    )
                ) ?? [];

              return {
                ...existing,
                ...incoming,
                moduleConnectivityStates: [
                  ...(existing.moduleConnectivityStates ?? []),
                  ...newModuleConnectivityStates,
                ],
              };
            },
          },
        },
      },
    },
  }),
});

import styled from "styled-components";
import { ModuleAuthorizationFragment } from "../../../data/generated";
import { formatTimestamp } from "../../../utils/date";
import { theme } from "../../../theme";

export const ModuleAuthorizationDetailsRow = ({
  moduleAuthorization,
}: {
  moduleAuthorization: ModuleAuthorizationFragment;
}) => {
  const { createdAt, moduleSerialNumber } = moduleAuthorization;

  return (
    <>
      <tr>
        <Td>{formatTimestamp(createdAt)}</Td>
        <Td>{moduleSerialNumber}</Td>
      </tr>
    </>
  );
};

const Td = styled.td<{
  $isCentered?: boolean;
  $isSecondary?: boolean;
}>`
  text-align: ${({ $isCentered }) => ($isCentered ? "center" : "left")};
  padding: 5px;
  color: ${({ $isSecondary }) =>
    $isSecondary ? theme.textColor.secondary : theme.textColor.default};
`;

import styled from "styled-components";
import {
  useGetWebhookRequestsQuery,
  WebhookRequestsFilters,
} from "../../../data/generated";
import { theme } from "../../../theme";
import { WebhookRequestDetailsRow } from "./WebhookRequestDetailsRow";
import { Loader } from "../../../design-system/atoms/Loader";

export const WebhookRequestsList = ({
  filters,
}: {
  filters: WebhookRequestsFilters;
}) => {
  const { data, loading, error, fetchMore } = useGetWebhookRequestsQuery({
    variables: { limit: 50, offset: 0, filters },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return null;
  }

  if (loading || !data) {
    return <Loader />;
  }

  const {
    webhookRequests: { hasMore, webhookRequests },
  } = data;

  function handleScroll({ currentTarget }: React.UIEvent<HTMLDivElement>) {
    if (
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight
    ) {
      if (hasMore) {
        fetchMore({
          variables: {
            offset: webhookRequests.length,
            limit: 50,
            filters,
          },
        });
      }
    }
  }

  return (
    <Container>
      <Table onScroll={handleScroll}>
        <HeaderRow>
          <HeaderCell style={{ width: 350 }}>Route</HeaderCell>
          <HeaderCell style={{ width: 70 }}>Status code</HeaderCell>
          <HeaderCell style={{ width: 170 }}>Requested at</HeaderCell>
          <HeaderCell style={{ width: 170 }}>Finished at</HeaderCell>
          <HeaderCell style={{ flex: 1, minWidth: 320 }}>Body</HeaderCell>
        </HeaderRow>
        {webhookRequests.length === 0 ? (
          <NoResultContainer>
            <p>No webhook request</p>
          </NoResultContainer>
        ) : (
          webhookRequests.map((webhookRequest) => (
            <WebhookRequestDetailsRow
              key={webhookRequest._id}
              webhookRequest={webhookRequest}
            />
          ))
        )}
      </Table>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  border: 1px solid ${theme.borderColor.highlight};
  border-radius: 10px;
  overflow: hidden;
  z-index: 0;
`;

const Table = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const HeaderRow = styled.div`
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 10px;
  background-color: ${theme.backgroundColor};
  z-index: 1;
`;

const HeaderCell = styled.div`
  padding: 5px;
  color: ${theme.textColor.default};
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  font-weight: bold;
`;

const NoResultContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${theme.textColor.secondary};
`;

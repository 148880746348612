import styled from "styled-components";
import {
  ConnectivityState,
  ModuleConnectivityStateFragment,
} from "../../../data/generated";
import { formatTimestamp } from "../../../utils/date";
import { theme } from "../../../theme";

export const ModuleConnectivityDetailsRow = ({
  moduleConnectivityState,
}: {
  moduleConnectivityState: ModuleConnectivityStateFragment;
}) => {
  const { createdAt, moduleSerialNumber, state } = moduleConnectivityState;

  return (
    <>
      <tr>
        <Td>{moduleSerialNumber}</Td>
        <Td>
          <StateLabel
            $color={
              state === ConnectivityState.Connected
                ? theme.textColor.highlight
                : theme.textColor.secondary
            }
            $isBold={state === ConnectivityState.Connected}
          >
            {state}
          </StateLabel>
        </Td>
        <Td>{formatTimestamp(createdAt)}</Td>
      </tr>
    </>
  );
};

const Td = styled.td<{
  $isCentered?: boolean;
  $isSecondary?: boolean;
}>`
  text-align: ${({ $isCentered }) => ($isCentered ? "center" : "left")};
  padding: 5px;
  color: ${({ $isSecondary }) =>
    $isSecondary ? theme.textColor.secondary : theme.textColor.default};
`;

const StateLabel = styled.div<{ $color: string; $isBold: boolean }>`
  color: ${({ $color }) => $color};
  font-weight: ${({ $isBold }) => ($isBold ? "600" : "normal")};
`;

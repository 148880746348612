import { Modal } from "../../../../design-system/organisms/Modal";
import { Button } from "../../../../design-system/atoms/Button";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useEjectSinglePowerBankMutation } from "../../../../data/generated";

export const EjectPowerBankModal = ({
  moduleSerialNumber,
  slotIndex,
  onClose,
}: {
  moduleSerialNumber: string;
  slotIndex: number;
  onClose: () => void;
}) => {
  const [ejectSinglePowerBankMutation] = useEjectSinglePowerBankMutation({
    variables: {
      moduleSerialNumber,
      slotIndex,
    },
  });

  async function ejectPowerBank() {
    const { data } = await ejectSinglePowerBankMutation();

    if (!data) {
      toast.error("An unexpected error occurred");
      return;
    }

    const {
      ejectSinglePowerBank: { success, error },
    } = data;

    if (error) {
      toast.error(error);
      return;
    }

    if (!success) {
      toast.error("An unexpected error occurred");
      return;
    }

    toast.success("Power bank popped out successfully");
    onClose();
  }

  return (
    <Modal title="Eject single power bank" onClose={onClose}>
      <ContentContainer>
        You're about to eject the power bank in the slot #{slotIndex} of the
        module with Serial Number {moduleSerialNumber}.
        <Button onClick={ejectPowerBank}>Confirm</Button>
      </ContentContainer>
    </Modal>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

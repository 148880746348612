import { Modal } from "../../../../design-system/organisms/Modal";
import { Button } from "../../../../design-system/atoms/Button";
import { toast } from "react-toastify";
import {
  ModuleFragment,
  useRestartModuleMutation,
} from "../../../../data/generated";
import styled from "styled-components";

export const RestartModuleModal = ({
  module,
  onClose,
}: {
  module: ModuleFragment;
  onClose: () => void;
}) => {
  const [restartModuleMutation] = useRestartModuleMutation({
    variables: { serialNumber: module.serialNumber },
  });

  async function ejectPowerBanks() {
    const { data } = await restartModuleMutation();

    if (!data) {
      toast.error("An unexpected error occurred");
      return;
    }

    const {
      restartModule: { success, error },
    } = data;

    if (error) {
      toast.error(error);
      return;
    }

    if (!success) {
      toast.error("An unexpected error occurred");
      return;
    }

    toast.success("Module restarted successfully");
    onClose();
  }

  return (
    <Modal title="Restart module" onClose={onClose}>
      <ContentContainer>
        You're about to restart the module with Serial Number{" "}
        {module.serialNumber}.<Button onClick={ejectPowerBanks}>Confirm</Button>
      </ContentContainer>
    </Modal>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

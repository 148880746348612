import styled from "styled-components";
import { Title } from "../../../../design-system/atoms/Title";
import { LogsPreview } from "./LogsPreview";
import { Link } from "../../../../design-system/atoms/icons/Link";
import { useNavigate } from "react-router-dom";

export const LogsTile = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Header onClick={() => navigate("/logs")}>
        <Title>Logs</Title>
        <Link width={24} height={24} fillColor="white" />
      </Header>
      <PreviewContainer>
        <LogsPreview />
      </PreviewContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const PreviewContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

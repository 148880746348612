import styled from "styled-components";
import { Title } from "../../../../design-system/atoms/Title";
import { useNavigate } from "react-router-dom";
import { Link } from "../../../../design-system/atoms/icons/Link";
import { PowerBanksInfo } from "./PowerBanksInfo";

export const PowerBanksTile = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Header onClick={() => navigate("/power-banks")}>
        <Title>PowerBanks</Title>
        <Link width={24} height={24} fillColor="white" />
      </Header>
      <PowerBanksInfo />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
  padding: 15px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const AccessPoint = ({
  width,
  height,
  fillColor,
}: {
  width: number;
  height: number;
  fillColor?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke={fillColor || "#111111"}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 12l0 .01" />
      <path d="M14.828 9.172a4 4 0 0 1 0 5.656" />
      <path d="M17.657 6.343a8 8 0 0 1 0 11.314" />
      <path d="M9.168 14.828a4 4 0 0 1 0 -5.656" />
      <path d="M6.337 17.657a8 8 0 0 1 0 -11.314" />
    </svg>
  );
};
